<template>
  <v-container
    fluid
    class="pa-0"
    :style="`max-height: ${($vuetify.breakpoint.height - 130).toString() + 'px'};`"
  >
    <v-container
      fluid
      class="px-3 mt-3"
      style="position: sticky; top: 0px; z-index: 1;"
    >
      <v-row
        class="mt-n6"
        justify="start"
        align="center"
      >
        <v-toolbar
          flat
          color="lightBackground"
        >
          <v-text-field
            v-model="search"
            :solo-inverted="soloInverted"
            :solo="solo"
            prepend-inner-icon="mdi-magnify"
            rounded
            :label="$t('actions|search_documents')"
            dense
            class="shrink"
            hide-details
            @focus="soloInverted = true; solo = false"
            @blur="soloInverted = false; solo = true"
          />
          <v-spacer />
          <v-btn
            v-if="!actualTab.isSharedWithMe"
            color="primary"
            rounded
            small
            class="mx-2 pl-4"
            @click="addDocumentAction(actualTab)"
          >
            <v-icon
              small
              class="mr-1"
            >
              mdi-upload
            </v-icon>
            {{ $t('actions|upload') }}
          </v-btn>
          <v-btn
            outlined
            small
            rounded
            color="primary"
            class="ml-4 mr-2"
            @click="addExportAction(filteredDocuments)"
          >
            <v-icon
              small
              class="mr-1"
            >
              mdi-export
            </v-icon>
            {{ $t('actions|export') }}
          </v-btn>
        </v-toolbar>
      </v-row>
    </v-container>
    <v-card
      :key="refreshDocumentsKey"
      class="documents-table-wrapper"
    >
      <v-menu
        v-model="showRightClickMenu"
        offset-y
        :position-x="menuPositionX"
        :position-y="menuPositionY"
      >
        <v-list>
          <v-list-item-group
            v-model="menuSelection"
          >
            <v-list-item
              v-for="(item, i) in rightClickMenuItems"
              :key="i"
              :disabled="checkDisabledStatus(item)"
              @click="fireActionEvent(item)"
            >
              <v-list-item-icon>
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-data-table
        v-model="selected"
        :headers="tableHeaders"
        :items="documentsAndFolders"
        :search="search"
        :single-select="singleSelect"
        item-key="_id"
        show-select
        dense
        no-results-text
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1 pt-2"
        some-items
        fixed-header
        :height="selected.length ? ($vuetify.breakpoint.height - 298).toString() + 'px' : ($vuetify.breakpoint.height - 260).toString() + 'px'"
        @contextmenu:row="rightClickHandler"
      >
        <template v-slot:top>
          <v-row
            v-if="selected.length"
            align="end"
            no-gutters
            class="ml-3 mb-1"
          >
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      icon
                      color="primary"
                      :loading="loading"
                      :disabled="getStatus(selected)"
                      @click="saveAsMultipleFiles"
                    >
                      <v-icon>
                        mdi-export
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span v-if="!!getStatus(selected)">{{ $t('expressions|currently_processing_doc') }}</span>
                <span v-else>{{ $t('actions|export') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      v-if="account.accountType === 'lawyer'"
                      icon
                      color="primary"
                      :disabled="getStatus(selected)"
                      @click="shareMultipleDocs(selected, actualTab)"
                    >
                      <v-icon>
                        mdi-share-variant
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span v-if="!!getStatus(selected)">{{ $t('expressions|currently_processing_doc') }}</span>
                <span v-else>{{ $t('actions|share') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      icon
                      color="primary"
                      :disabled="getStatus(selected)"
                      @click="addAssignActionMultiple"
                    >
                      <v-icon>
                        mdi-paperclip
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span v-if="!!getStatus(selected)">{{ $t('expressions|currently_processing_doc') }}</span>
                <span v-else>{{ $t('actions|assign') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    color="primary"
                    v-on="on"
                    @click="deleteMultipleDocuments"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('actions|delete') }}</span>
              </v-tooltip>
            </template>
          </v-row>
        </template>
        <template v-slot:body="props">
          <!-- <template v-slot:item="item"> -->
          <div
            v-for="(itm, idx) in filteredFolders"
            :key="idx"
            style="width: 50px;"
          >
            <draggable
              :list="filteredFolders"
              tag="tbody"
              draggable=".doc"
              :group="{ name: itm._id, pull: false, put: ['docTableDocs'] }"
              class="folder-list-group"
              ghost-class="ghost"
              :move="onMoveCallback"
              @end="onDropCallback"
              @start="onDragChange"
            >
              <tr>
                <td
                  :id="itm._id"
                >
                  <v-checkbox
                    :id="itm._id"
                    v-model="selected[idx]"
                  />
                </td>
                <td
                  :id="itm._id"
                >
                  <v-container
                    :id="itm._id"
                    style="width: 100vw;"
                  >
                    <v-row
                      :id="itm._id"
                      justify="start"
                      align="center"
                    >
                      <v-col
                        :id="itm._id"
                        cols="12"
                      >
                        <v-icon
                          :id="itm._id"
                          class="mb-1 mr-1"
                        >
                          mdi-folder-outline
                        </v-icon>
                        <span
                          :id="itm._id"
                          style="cursor: pointer;"
                        >
                          {{ itm.raw.originalname }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
                <td />
              </tr>
            </draggable>
          </div>
          <v-divider />

          <draggable
            :list="filteredDocuments"
            tag="tbody"
            draggable=".doc"
            :group="{ name: 'docTableDocs', pull: true, put: false }"
            class="document-list-group"
            :move="onMoveCallback"
            @end="onDropCallback"
            @start="onDragChange"
          >
            <tr
              v-for="(item, index) in filteredDocuments"
              :key="index"
              :class="item.collectionType && item.collectionType === 'Folders' ? '' : 'doc'"
            >
              <td v-if="item.collectionType && item.collectionType === 'Documents'">
                <v-checkbox
                  v-model="selected[index]"
                />
              </td>
              <td v-if="item.collectionType && item.collectionType === 'Documents'">
                <v-container
                  class="ml-n4"
                  style="width: 300px;"
                >
                  <v-row
                    justify="start"
                    align="center"
                  >
                    <v-col
                      cols="1"
                    >
                      <v-icon
                        v-if="isIcon(item.fileType, item.raw.originalname)"
                        :color="getItemColor(item.fileType)"
                      >
                        {{ getIconType(item.fileType, item.raw.originalname) }}
                      </v-icon>
                    </v-col>
                    <v-col
                      cols="11"
                      class="px-4"
                    >
                      <img
                        v-if="(item.fileType === 'application/vnd.apple.pages' || item.fileType === 'application/vnd.apple.numbers') && item.fileType !== 'application/octet-stream'"
                        :src="getSvg(item.fileType, item.raw.originalname)"
                        height="24px"
                        width="auto"
                      >
                      <img
                        v-if="item.fileType === 'application/octet-stream' && !isIcon(item.fileType, item.raw.originalname)"
                        :src="getOctetValue(item.raw.originalname)"
                        height="24px"
                        width="auto"
                      >
                      <span
                        style="cursor: pointer;"
                        @click="openFile(item)"
                      >
                        {{ item.raw.originalname }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </td>
              <td>
                <v-row
                  v-if="item.collectionType && item.collectionType === 'Documents'"
                >
                  <v-col cols="12">
                    <div>
                      {{ formatBytes(item.size) }}
                    </div>
                  </v-col>
                </v-row>
              </td>
              <td>
                <v-row v-if="item.collectionType && item.collectionType === 'Documents'">
                  <v-col cols="12">
                    <div
                      v-if="item.status === 'pending'"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-progress-circular
                            v-if="item.status === 'pending'"
                            indeterminate
                            size="22"
                            :width="3"
                            color="accent"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>{{ $t('documents|processing_document') }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-if="item.status === 'error'"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <span>{{ $t('documents|processing_error') }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-if="item.status === 'processed'"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="item.status === 'processed'"
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-check-circle
                          </v-icon>
                        </template>
                        <span>{{ $t('documents|processed_successfully') }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </td>
              <td>
                <CustomAutocomplete
                  v-if="item.collectionType && item.collectionType === 'Documents'"
                  :item="item"
                  :item-text="item.documentType.text"
                  :index="index"
                  :fields="_fields"
                  :used-in-documents-table="true"
                  :component-is-used-in="'documentsTable'"
                  @set-input-value="setInputValue"
                />
              </td>
              <td>
                <v-chip
                  v-if="item.collectionType && item.collectionType === 'Documents' && item.clientName"
                  outlined
                  small
                  color="primary"
                >
                  <v-icon
                    class="mr-2"
                    x-small
                  >
                    mdi-account
                  </v-icon>
                  {{ item.clientName }}
                  <v-icon
                    x-small
                    class="ml-2"
                    @click="unassignDocumentClient(item, item.clientName)"
                  >
                    mdi-close
                  </v-icon>
                </v-chip>
              </td>
              <td>
                <div v-if="item.collectionType && item.collectionType === 'Documents'">
                  <v-chip
                    v-for="(c, i) in item.cases"
                    :key="i"
                    outlined
                    small
                    color="primary"
                    class="ma-1"
                  >
                    <v-icon
                      class="mr-2"
                      x-small
                    >
                      mdi-briefcase-edit
                    </v-icon>
                    {{ c.caseName }}
                    <v-icon
                      class="ml-2"
                      x-small
                      @click="unassignDocument(item, c)"
                    >
                      mdi-close
                    </v-icon>
                  </v-chip>
                </div>
              </td>
              <td>
                <div>
                  {{ item.createdAt }}
                </div>
              </td>
              <td>
                <div
                  v-if="item.collectionType && item.collectionType === 'Documents'"
                  class="d-flex justify-center"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          v-bind="attrs"
                          color="primary"
                          :disabled="item.status === 'pending'"
                          v-on="on"
                          @click="openFile(item)"
                        >
                          mdi-file-eye
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}</span>
                    <span v-else>{{ $t('actions|open') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          size="20"
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="updateRawData(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}</span>
                    <span v-else>{{ $t('actions|change_document_name') }}</span>
                  </v-tooltip>

                  <v-menu
                    offset-y
                    min-width="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        class="mr-2"
                        v-on="on"
                      >
                        mdi-account-group
                      </v-icon>
                    </template>
                    <v-list
                      v-if="item.type && item.type !== 'folder'"
                      dense
                      class="pa-3"
                    >
                      <span class="ml-2">
                        {{ $t('common|uploaded_by') }}:
                      </span>
                      <v-list-item v-if="item.onCreatedBy === 'Account'">
                        <v-list-item-icon>
                          <v-avatar
                            size="30px"
                            class="ml-1 mr-1"
                          >
                            <img
                              v-if="item.createdBy && item.createdBy.avatar"
                              alt="Avatar"
                              :src="`${cfg.baseURL}downloadAvatar/${item.createdBy._id}/${item.createdBy.avatar.raw.filename}`"
                            >
                            <v-avatar
                              v-else
                              color="primary"
                            >
                              <v-icon
                                color="white"
                                small
                              >
                                mdi-account
                              </v-icon>
                            </v-avatar>
                          </v-avatar>
                        </v-list-item-icon>
                        <span
                          class="text-truncate"
                        >
                          {{ getName(item.createdBy) }}
                        </span>
                        <!-- {{ item.createdBy.accountData.accountName ? item.createdBy.accountData.accountName : account.accountName }} -->
                      </v-list-item>
                      <v-list-item v-if="item.onCreatedBy === 'Company'">
                        <v-list-item-icon>
                          <v-icon
                            color="primary"
                          >
                            mdi-domain
                          </v-icon>
                        </v-list-item-icon>
                        {{ item.createdBy ? item.createdBy.companyData.companyName : 'not available' }} (Company)
                      </v-list-item>
                      <v-list-item v-if="item.onCreatedBy === 'Group'">
                        <v-list-item-icon>
                          <v-icon
                            color="primary"
                          >
                            mdi-domain
                          </v-icon>
                        </v-list-item-icon>
                        {{ item.createdBy ? item.createdBy.groupName : 'not available' }} (Group)
                      </v-list-item>
                      <span class="ml-2">
                        {{ $t('common|shared_with') }}:
                      </span>
                      <v-list-item
                        v-for="acc in item.sharedWith.accounts"
                        :key="acc._id"
                      >
                        <v-list-item-icon>
                          <v-avatar
                            size="30px"
                            class="ml-1 mr-1"
                          >
                            <img
                              v-if="acc && acc.avatar"
                              alt="Avatar"
                              :src="`${cfg.baseURL}downloadAvatar/${acc._id}/${acc.avatar.raw.filename}`"
                            >
                            <v-avatar
                              v-else
                              color="primary"
                            >
                              <v-icon
                                color="white"
                                small
                              >
                                mdi-account
                              </v-icon>
                            </v-avatar>
                          </v-avatar>
                        </v-list-item-icon>
                        <span
                          class="text-truncate"
                        >
                          {{ getName(acc) }}
                        </span>
                      </v-list-item>
                      <v-list-item
                        v-for="c in item.sharedWith.company"
                        :key="c._id"
                      >
                        <v-list-item-icon>
                          <v-icon color="primary">
                            mdi-domain
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ c.companyData.companyName }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-for="g in item.sharedWith.groups"
                        :key="g._id"
                      >
                        <v-list-item-icon>
                          <v-icon color="primary">
                            mdi-account-group
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ g.groupName }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="saveAsSingleFile(item)"
                        >
                          mdi-export
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}.</span>
                    <span v-else>{{ $t('actions|export') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          v-if="account.accountType === 'lawyer'"
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="shareItemAction(item, actualTab)"
                        >
                          mdi-share-variant
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}.</span>
                    <span v-else>{{ $t('actions|share') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="addAssignAction(item)"
                        >
                          mdi-paperclip
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}</span>
                    <span v-else>{{ $t('actions|assign') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          v-if="(!item.collectionType && item.collectionType !== 'Folders') && item.pinned && item.pinned.includes(account._id)"
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="removePinFromDashboard(item)"
                        >
                          mdi-pin
                        </v-icon>
                        <v-icon
                          v-else
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="pinToDashboard(item)"
                        >
                          mdi-pin-outline
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status && item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}</span>
                    <span v-if="item.status && item.status !== 'pending' && item.pinned.includes(account._id)">{{ $t('actions|remove_from_pinboard') }}</span>
                    <span v-if="item.status && item.status !== 'pending' && !item.pinned.includes(account._id)">{{ $t('actions|add_to_pinboard') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                        @click="deleteDocumentAction(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>{{ $t('actions|delete') }}</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>
    <AssignMultipleDocuments
      :selected="selected"
    />
    <FileView
      ref="fileView"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { mdiChevronDown, mdiCog } from '@mdi/js'
import generalMixin from '@/utils/generalMixin'
import DocumentMixin from './DocumentMixins/DocumentMixin'
import moment from 'moment'
import cfg from '@/config'
import FileView from '../../components/dialogs/FileView.vue'
import AssignMultipleDocuments from '../../components/dialogs/AssignMultipleDocuments.vue'
import CustomAutocomplete from '../../components/InputFields/CustomAutocomplete.vue'
import draggable from 'vuedraggable'
const createdAtFormat = date => moment(date).format('YYYY-MM-DD')

export default {
  components: {
    FileView,
    AssignMultipleDocuments,
    CustomAutocomplete,
    draggable
  },
  mixins: [generalMixin, DocumentMixin],
  props: {
    group: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isMyTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    isSharedWithMe: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    },
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      cfg,
      loading: false,
      refreshDocumentsKey: 0,
      dialog: false,
      dialogDelete: false,
      sortBy: 'createdAt',
      sortDesc: true,
      singleSelect: false,
      selected: [],
      search: '',
      soloInverted: false,
      solo: true,
      selectedItem: '',
      icons: {
        arrowDown: mdiChevronDown,
        cog: mdiCog
      },
      innerDocuments: [],
      documentClient: null,
      documentCases: [],
      selectedLanguage: null,
      showRightClickMenu: false,
      menuPositionX: null,
      menuPositionY: null,
      actualRightClickedRow: null,
      menuSelection: null,
      inputFocus: null,
      query: null,
      dragArea: []
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      clients: state => state.clients.clients,
      documents: state => state.documents.documents,
      sharedWithMeDocuments: state => state.documents.sharedWithMeDocuments,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments,
      folders: state => state.documents.folders,
      settings: state => state.settings.settings
    }),
    _input () {
      return {
        'color': '#234e79',
        'font-size': '12px',
        paddingRight: '30px',
        paddingLeft: '8px',
        paddingTop: '4px',
        paddingBottom: '4px',
        'text-align': 'start',
        'width': '180px',
        'outline-width': '1px',
        'outline-style': 'solid',
        'outline-color': '#234e79',
        'border-radius': '25px'
      }
    },
    _inputFocus () {
      return {
        color: '#234e79',
        fontSize: '12px',
        paddingRight: '30px',
        paddingLeft: '25px',
        paddingTop: '4px',
        paddingBottom: '4px',
        width: '180px',
        'text-align': 'start',
        'outline-width': '1px',
        'outline-style': 'solid',
        'outline-color': '#234e79',
        'border-radius': '25px'
      }
    },
    documentSuggestions () {
      const suggestions = Object.keys(this.settings.fields).reduce((acc, key) => {
        const item = this.settings.fields[key]
        if (item.type === 'file-input') {
          acc[key] = item
        }
        return acc
      }, {})
      return suggestions
    },
    _fields () {
      let _filtered
      const suggestions = Object.keys(this.documentSuggestions).map((key) => {
        let name = this.documentSuggestions[key].label[this.selectedLanguage] ?? undefined
        if (Array.isArray(this.documentSuggestions[key].label[this.selectedLanguage])) {
          name = this.documentSuggestions[key].label[this.selectedLanguage].join(' / ')
        }
        return {
          name,
          fieldKey: key,
          language: this.selectedLanguage
        }
      })
      // remove suggestions with no label in this actual language
      const removeUndefined = (obj) => {
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop) && obj[prop] === undefined) {
            return
          }
        }
        return obj
      }
      _filtered = suggestions.filter(removeUndefined)
      if (!_filtered.length) _filtered.push({ name: this.$t('common|no_results_found') })
      return _filtered
    },
    filteredFields () {
      if (!this.query) return this._fields
      return this._fields.filter(field => {
        if (field.name.toLowerCase().includes(this.query.toLowerCase())) return field
      })
    },
    filteredDocumentsByPermissions () {
      let actualDocuments = []
      if (this.isSingleAcc && !this.isLavviraTab && !this.isSharedWithMe) {
        if (this.documents && this.documents.length) {
          actualDocuments = this.documents
        }
      }
      if (this.isMyTab && !this.isCompanyTab && !this.isGroupTab && !this.isLavviraTab && !this.isSingleAcc && this.userRole !== 'regular') {
        if (this.documents && this.documents.length) {
          actualDocuments = this.documents
        }
      }
      if (this.isCompanyTab && this.company) {
        actualDocuments = this.companyDocuments
      }
      if (this.isGroupTab && this.group) {
        for (let i = 0; i < this.groupDocuments.length; i++) {
          const groupDoc = this.groupDocuments[i]
          groupDoc.sharedWith.groups.forEach(group => {
            if (this.group._id === group._id) {
              actualDocuments.push(groupDoc)
            }
          })
        }
      }
      if (this.isSharedWithMe) {
        const myAccountId = this.account._id
        actualDocuments = this.sharedWithMeDocuments
        return actualDocuments.filter(function (doc) {
          const is = (typeof doc.createdBy)
          if (is === 'string') {
            return doc.createdBy !== myAccountId
          } else {
            return doc.createdBy._id !== myAccountId
          }
        })
      }
      return actualDocuments || []
    },
    filteredDocuments () {
      return this.filteredDocumentsByPermissions.map((doc, index) => {
        const client = doc.clients[0]
        let clientName = ''
        if (client && client.clientData) {
          clientName = client && client.clientType === 'individual' ? client.clientData.given_names + ' ' + client.clientData.surname : client.clientData.company_name
        }
        return {
          ...doc,
          updatedAt: createdAtFormat(doc.updatedAt),
          createdAt: createdAtFormat(doc.createdAt),
          fileType: doc.raw.mimetype,
          documentType: {
            key: doc.info.document_type,
            text: this.getFieldValue(doc.info.document_type, this.selectedLanguage)
            // getFieldValue => in generalMixin/utils available
          },
          clientName,
          index: index,
          size: doc.raw.size,
          docTypeMenuActive: false
        }
      })
    },
    filteredFolders () {
      return this.folders.map((folder) => {
        return {
          ...folder,
          updatedAt: createdAtFormat(folder.updatedAt),
          createdAt: createdAtFormat(folder.createdAt)
        }
      })
    },
    documentsAndFolders () {
      return [...this.filteredFolders, ...this.filteredDocuments]
    },
    tableHeaders () {
      const headers = [
        {
          text: this.$t('documents|name'),
          align: 'start',
          class: 'mt-4',
          sortable: false,
          value: 'raw.originalname'
        },
        {
          text: this.$t('documents|file_size'),
          align: 'center',
          class: 'mt-4',
          sortable: true,
          value: 'size',
          width: '120px'
          // sort: (a, b) => {}
        },
        {
          text: this.$t('common|status'),
          align: 'center',
          class: 'mt-4',
          sortable: true,
          value: 'status',
          width: '80px'
        },
        {
          text: this.$t('documents|document_type'),
          align: 'center',
          class: 'mt-4',
          width: '180',
          sortable: true,
          value: 'documentType'
        },
        {
          text: this.$t('clients|client'),
          align: 'center',
          class: 'mt-4',
          sortable: true,
          value: 'clientName'
        },
        {
          text: this.$t('cases|cases'),
          align: 'center',
          class: 'mt-4',
          sortable: true,
          value: 'cases'
        },
        {
          text: this.$t('common|date_created'),
          align: 'center',
          class: 'mt-4',
          sortable: true,
          value: 'createdAt',
          width: '100'
        },
        {
          text: this.$t('actions|actions'),
          value: 'actions',
          class: 'mt-4',
          sortable: false,
          align: 'center'
        }
      ]
      return headers
    },
    rightClickMenuItems () {
      return [
        {
          text: this.$t('actions|open'),
          icon: 'mdi-file-eye',
          action: 'open-doc'
        },
        {
          text: 'Create folder',
          icon: 'mdi-folder-plus-outline',
          action: 'create-folder'
        },
        {
          text: this.$t('actions|change_document_name'),
          icon: 'mdi-pencil',
          action: 'change-name'
        },
        {
          text: this.$t('actions|export'),
          icon: 'mdi-export',
          action: 'export-doc'
        },
        {
          text: this.$t('actions|share'),
          icon: 'mdi-share-variant',
          action: 'share-doc'
        },
        {
          text: this.$t('actions|assign'),
          icon: 'mdi-paperclip',
          action: 'assign-doc'
        },
        {
          text: this.$t('actions|delete'),
          icon: 'mdi-delete',
          action: 'delete-doc'
        }
      ]
    }
  },
  watch: {
    documents: {
      handler (oldVal, newVal) {
        if (newVal) {
          this.refreshDocumentsKey++
        }
      },
      deep: true
    },
    companyDocuments: {
      handler (oldVal, newVal) {
        if (newVal) {
          this.refreshDocumentsKey++
        }
      },
      deep: true
    },
    groupDocuments: {
      handler (oldVal, newVal) {
        if (newVal) {
          this.refreshDocumentsKey++
        }
      },
      deep: true
    },
    showRightClickMenu (val) {
      if (!val) {
        this.menuSelection = null
        // document.oncontextmenu = function () { return true }
      }
    }
  },
  updated () {
    const lang = localStorage.getItem('preferedLanguage') || 'en'
    if (lang) {
      this.selectedLanguage = lang
    }
  },
  created () {
    const lang = localStorage.getItem('preferedLanguage') || 'en'
    if (lang) {
      this.selectedLanguage = lang
    }
  },
  beforeDestroy () {
    document.oncontextmenu = function () { return true }
  }
  // ALL METHODS IN DOCUMENT MIXIN AVAILABLE
}
</script>

<style lang="scss" scoped>
  .documents-table-wrapper {
    width: 100%;
  }
</style>

<style lang="scss">
  .v-data-table-header th {
    white-space: nowrap;
  }
  .v-data-table-header {
    height: 45px;
  }
  .v-data-table tr:hover {
    background-color: #F6F4F4 !important;
    & .input-label-doc-table {
      position: absolute;
      left: 20px;
      top: -8px;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 0px;
      padding-bottom: 0px;
      white-space: nowrap;
      color: #234e79;
      background-color: #F6F4F4;
      font-size: 9px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
    }
  }
  .input-label-doc-table {
    position: absolute;
    left: 20px;
    top: -8px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    white-space: nowrap;
    color: #234e79;
    background-color: white;
    font-size: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }

  // comment this out to fix the placeholder opacity in Firefox
  ::-moz-placeholder {
    opacity: 1;
  }
</style>
